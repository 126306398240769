
































































































































// Vue
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
// vue-draggable
import draggable from "vuedraggable";
// Services
import AdminService from "./Admin.service";
// Models
import { IQuarry } from "@/core/_models/quarry.model";
// Components
import UpdateQuarryDialog from "@/views/admin/_dialogs/quarries/UpdateQuarryDialog.vue";
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";

export default Vue.extend({
  name: "Quarries",
  components: { draggable, ConfirmationDialog, UpdateQuarryDialog },

  data() {
    return {
      isLoading: false,
      isSearching: false,

      search: "",

      // Quarries data
      quarriesData: undefined as IQuarry[] | undefined
    };
  },

  mounted() {
    this.getAllQuarries();
  },

  // Update the indexes of all Quarries on Destroy
  beforeDestroy() {
    this.saveLayout();
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    saveLayout(): void {
      this.isLoading = true;

      // Handle saving with no Quarries.
      if (!this.quarriesData?.length) {
        this.isLoading = false;
        return;
      }

      let index = 0;
      this.quarriesData.forEach((element: IQuarry) => {
        element.Index = index;
        index++;

        AdminService.updateQuarry(this.currentUser.clientId, element).then(() => {
          this.isLoading = false;
        });
      });
    },

    getAllQuarries(): void {
      AdminService.getAllQuarries(this.currentUser.clientId).then((data: IQuarry[]) => {
        if (!data) return;
        this.quarriesData = data;
      });
    },

    deleteQuarry(quarry: IQuarry): void {
      this.isLoading = true;

      AdminService.deleteQuarry(this.currentUser.clientId, quarry.DatabaseId!)
        .then(() => {
          this.getAllQuarries();
          this.ADD_SUCCESS_MESSAGE("Quarry successfully deleted.");
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE("Error deleting quarry. Please try again.");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    resetSearch(): void {
      this.search = "";
      this.isSearching = false;
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    filteredQuarries(): any {
      if (!this.quarriesData?.length) return;

      return this.quarriesData.filter((element: IQuarry): any => {
        return element.Name?.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    /**
     * Animations for Drag n Drop (see docs below)
     * https://www.npmjs.com/package/vuedraggable
     * https://sortablejs.github.io/Vue.Draggable/#/transition-example-2
     * https://github.com/SortableJS/Vue.Draggable/blob/master/example/components/transition-example-2.vue
     * @author Nick Brahimir
     */
    dragOptions(): any {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost" // Corresponds to the scoped scss class.
      };
    },

    dragOff(): any {
      return {
        disabled: true
      };
    }
  }
});
